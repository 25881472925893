<template>
  <div class="app-container">
    <el-form>
      <el-form-item label="OpenScreen Enabled">
        <el-checkbox v-model="param.enabled"></el-checkbox>
      </el-form-item>
      <el-form-item label="Image">
        <single-upload v-model="param.url"></single-upload>
      </el-form-item>
      <el-form-item>
        <el-button @click="submit">Submit</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { fetchOpenScreen, updateOpenScreen } from '@/api/homepage';
import SingleUpload from '@/components/Upload/singleUpload';

export default {
  name: 'OpenScreen',
  components: { SingleUpload },
  data() {
    return {
      param: {
        enabled: null,
        url: null,
      },
    };
  },
  created() {
    fetchOpenScreen().then((response) => {
      this.param = response.data;
    });
  },
  methods: {
    submit() {
      if (this.param.enabled && this.param.url == null) {
        this.$message({
          message: '请设置图片',
          type: 'warning',
          duration: 1000,
        });
        return;
      }
      updateOpenScreen(this.param).then(() => {
        this.$message({
          message: 'Success',
          type: 'success',
          duration: 1000,
        });
      });
    },
  },
};
</script>
