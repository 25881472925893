import request from '@/utils/request';

export function fetchOpenScreen() {
  return request({
    url: '/v1/homepage/getOpenScreen',
    method: 'get',
  });
}

export function updateOpenScreen(data) {
  return request({
    url: '/v1/homepage/setOpenScreen',
    method: 'post',
    data,
  });
}
